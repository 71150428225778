import React, { useState, useEffect, useRef } from 'react';
import apiRequest from '../utils';
import loadData from '../pages/Data'
import Modal from '../components/Modal';


function ResultsTable({ selectedPerson, labResults, loadData }) {

    let itemRefs = useRef([])
    let valueRefs = useRef([])
    let trRefs = useRef([])
    const [manualValue, setManualValue] = useState('')
    const [manualCompany, setManualCompany] = useState('')
    const [insertingManual, setInsertingManual] = useState(false)
    const [LOINCCodes, setLOINCCodes] = useState([]);
    const [showToast, setShowToast] = useState(false); // State for toast visibility
    const toastRef = useRef(null); // Reference to the toast element
    const [error, setError] = useState('')

    const validateInputs = () => {
        if (!manualCompany) {
            setError('Company/Note cannot be empty.');
            return false;
        }
        if (!manualValue || isNaN(manualValue)) {
            setError('Please enter a valid numeric value.');
            return false;
        }
        setError('');  // Clear any previous error messages
        return true;
    };

    const loadLOINCCodes = async () => {
        let request = await apiRequest("tests/", "GET", true, localStorage.getItem("token"));
        if (request.status === 200) {
            setLOINCCodes(request["data"]);
        }
    };

    const addResult = async () => {
        if (!validateInputs()) {
            return;
        }

        setInsertingManual(true);

        const currentDate = new Date();

        const formattedCurrentDate = new Intl.DateTimeFormat('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23',
        }).format(currentDate).replace(',', '');

        const person = selectedPerson === '' ? '' : selectedPerson ; // Treat empty string as the logged-in user
        let request = await apiRequest(`results/manual/${person}`, "POST", true, localStorage.getItem("token"), JSON.stringify({
            "test_loinc": labResults[0].test_loinc,
            "company": manualCompany,
            "result_value": manualValue,
            "date": formattedCurrentDate
        }));

        if (request.status == 201) {
            setManualCompany('');
            setManualValue('');
            setInsertingManual(false);
            loadData(true);
        } else {
            setError('Failed to insert result.');
            setInsertingManual(false);
        }
    };

    const [isEmpty, setIsEmpty] = useState(false)
    const [isEditable, setIsEditable] = useState({})

    const deleteItem = async (idx, data_point) => {
        let res = await apiRequest(`result/${data_point.id}`, "DELETE", true, localStorage.getItem("token"))

        if (res["status"] === 200) {
            if (labResults.length == 1) { setIsEmpty(true) }
            try {
                trRefs.remove()
            }
            catch {

            }
            finally {
                loadData(true);
            }

        }
    }

    const editItem = async (id, value) => {
        const newValue = JSON.stringify({ value })
        let res = await apiRequest(`result/${id}`, "PUT", true, localStorage.getItem("token"), newValue)

        if (res["status"] === 200) {
            loadData(true);
        }
        return false
    }

    const makeItemEditable = async (idx, id) => {
        let newEditableDict = { ...isEditable }

        if (newEditableDict[id]) {
            editItem(id, valueRefs.current[idx].innerText);
        }

        newEditableDict[id] = !newEditableDict[id]
        setIsEditable(newEditableDict)
    }

    useEffect(() => {
        let isEditableDict = {}
        labResults.map((data_point) => { isEditableDict[data_point.id] = false })
        setIsEditable(isEditableDict)
    }, [])

    return (
        <>
            <div id="data-table">
                {!isEmpty &&
                    <table className="table table-responsive table-borderless" data-bs-theme="dark">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Company</th>
                                <th>Value</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>


                            {labResults.map((data_point, idx) => (
                                <tr key={idx} ref={(element) => { trRefs.current[idx] = element }}>
                                    <td>{data_point.date}</td>
                                    <td>{data_point.company}</td>
                                    <td ref={(element) => { valueRefs.current[idx] = element }} contentEditable={isEditable[data_point.id]}>{data_point.result_value}</td>
                                    <td>
                                        <div className="table-action-btns">
                                            <button
                                                ref={(element) => { itemRefs.current[idx] = element }}
                                                className={`btn btn-sm ${isEditable[data_point.id] ? 'btn-outline-success' : 'btn-outline-light'}`}
                                                onClick={() => { makeItemEditable(idx, data_point.id) }}>
                                                {isEditable[data_point.id] ? <i class="bi bi-check-circle-fill"></i> : <i class="bi bi-pencil"></i>}
                                            </button>
                                            <button
                                                className='btn btn-sm btn-outline-danger'
                                                onClick={async () => { await deleteItem(idx, data_point) }}>
                                                <i class="bi bi-trash3"></i>

                                            </button>

                                        </div>
                                    </td>

                                </tr>
                            ))
                            }
                        </tbody>
                    </table>

                }

            </div>
            <div id="data-table-add">
            <div className='row mt-3'>
                <div className="col">
                    <input
                        type="text"
                        className='form-control'
                        placeholder='Company/Note'
                        value={manualCompany}
                        onInput={(e) => setManualCompany(e.target.value)}
                    />
                </div>
                <div className="col">
                    <input
                        type="text"
                        placeholder='Value'
                        className='form-control'
                        value={manualValue}
                        onInput={(e) => setManualValue(e.target.value)}
                    />
                </div>
                <div className="col d-grid">
                    <button
                        type="button"
                        className="btn btn-success btn-block"
                        onClick={() => addResult()}
                        disabled={insertingManual}
                    >
                        {insertingManual ? "Inserting.." : "Add"}
                    </button>
                </div>
                {error && <div className="alert alert-danger mt-3" role="alert">{error}</div>}
            </div>
            </div>
        </>
    )
        ;
};

export default ResultsTable;