import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Data from "./pages/Data";
import Activate from "./pages/Activate";
import './mvp.css'
import Signup from "./pages/Signup";
import ForgotPass from "./pages/ForgotPass";
import Settings from "./pages/Settings";
import Landing from './pages/Landing'

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/reset/*" element={<Activate />} />
        <Route path="/activate/*" element={<Activate />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotPass" element={<ForgotPass />} />
        <Route path="/login" element={<Login />} />
        <Route path="/data" element={<Data />} />
        <Route path="/" element={<Landing />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </Router>
  );
}

export default App;
